import React from 'react'
import headerlogo from '../images/Homeitems-logo-mini.png'
import './Header.css'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, Image } from 'react-bootstrap'

const Header = () => {
  return (
    <header>
      <Navbar
        expand='lg'
        collapseOnSelect
        style={{ backgroundColor: '#e2e2e2', border: 'none' }}
      >
        <Container fluid>
          <LinkContainer to='/'>
            <Navbar.Brand>
              <Image
                src={headerlogo}
                alt='Header Logo'
                style={{ width: '20%' }}
              />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ml-auto'>
              <LinkContainer to='/'>
                <Nav.Link className='menu-links'>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/services'>
                <Nav.Link className='menu-links'>Services</Nav.Link>
              </LinkContainer>
              <LinkContainer to='/projects'>
                <Nav.Link className='menu-links'>Projects</Nav.Link>
              </LinkContainer>
              <Nav.Link className='menu-links' href='/#contact'>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
