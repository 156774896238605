import React from 'react'
import { Card } from 'react-bootstrap'

const ServiceCard = ({ data }) => {
  return (
    <>
      <Card key={data.id} className='border-0 mt-3'>
        <Card.Img
          className='p-3'
          width='100%'
          height='150px'
          src={data.url}
          alt={data.title}
        />
        <Card.Body>
          <Card.Title>{data.title}</Card.Title>
          <Card.Text>{data.desc}</Card.Text>
        </Card.Body>
      </Card>
    </>
  )
}

export default ServiceCard
