import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import MainLogo from '../images/Homeitems-logo.svg'
import ProjectsBackgroundImage from '../images/Four-Seasons-Dubai.jpg'
import Footer from '../components/Footer'
import ProjectCard from '../components/ProjectCard'
import ProjectData from '../data/projectsdata'
import { motion } from 'framer-motion'

const ProjectScreen = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: '-100vw',
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: '100vw',
      scale: 1.2,
    },
  }

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }
  return (
    <motion.div
      exit='out'
      animate='in'
      initial='initial'
      variants={pageVariants}
      transition={pageTransition}
    >
      <Row className='mr-0 ml-0' style={{ width: '100%' }}>
        <Col className='p-0'>
          <div
            className='services-background-image d-flex justify-content-center align-items-center'
            style={{ backgroundImage: 'url(' + ProjectsBackgroundImage + ')' }}
          >
            <Image src={MainLogo} className='logo rounded'></Image>
          </div>
        </Col>
      </Row>
      <Container className='py-5'>
        <Row className='text-center'>
          <Col>
            <h1>Projects</h1>
          </Col>
        </Row>
        <Row>
          {ProjectData.map((data) => (
            <Col key={data.id} sm={12} xs={12} md={4} lg={4}>
              <ProjectCard data={data} />
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </motion.div>
  )
}

export default ProjectScreen
