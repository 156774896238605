import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Container, Col, Row, Image } from 'react-bootstrap'
import BluEdgeLogo from '../images/white_logo.svg'

const Footer = () => {
  return (
    <footer>
      <Container className='py-5'>
        <Row className='text-center'>
          <Col lg={4} md={4} sm={12} xs={12}>
            <h5 style={{ fontSize: '1rem', cursor: 'default' }}>
              Useful Links
            </h5>
            <LinkContainer to='/services'>
              <p>Services</p>
            </LinkContainer>
            <LinkContainer to='/projects'>
              <p>Projects</p>
            </LinkContainer>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <h5 style={{ fontSize: '1rem', cursor: 'default' }}>Contact Us</h5>
            <p>Address: Sheikh Zayed road, P.O: 90068 Dubai - UAE</p>
            <a href='tel:+971506556977'>
              <p>Mobile: +971 506556977</p>
            </a>
            <a href='tel:+97145579908'>
              <p>Office: +971 45579908</p>
            </a>
            <a href='fax:+97145579908'>
              <p>Fax: +971 42665461</p>
            </a>
            <a href='mailto:admin@homeitems.ae'>
              <p>Email: admin@homeitems.ae</p>
            </a>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className='social-media-icons'>
              <ul className='social-network'>
                <li>
                  <div className='social-icon'>
                    <a href='/' rel='noreferrer' target='_blank'>
                      <i className='fab fa-facebook-f'></i>
                    </a>
                  </div>
                </li>
                <li>
                  <div className='social-icon'>
                    <a href='/' rel='noreferrer' target='_blank'>
                      <i className='fab fa-instagram'></i>
                    </a>
                  </div>
                </li>
                <li>
                  <div className='social-icon'>
                    <a href='/' rel='noreferrer' target='_blank'>
                      <i className='fab fa-youtube'></i>
                    </a>
                  </div>
                </li>
                <li>
                  <div className='social-icon'>
                    <a href='/' rel='noreferrer' target='_blank'>
                      <i className='fab fa-linkedin-in'></i>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='text-center pt-3 copyrights'>
            <a href='https://www.bluedge.me/' rel='noreferrer' target='_blank'>
              <Image src={BluEdgeLogo} />
              Created & Developed by BluEdge
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
