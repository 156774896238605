import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Stick from '../images/stick.png'
import GreyStick from '../images/grey-stick.png'
import MainLogo from '../images/Homeitems-logo.svg'
import BackgourndImage from '../images/Port-de-mer-background.jpg'
import CompanyHistory from '../images/company-history.png'
import ProjectImage from '../images/port-de-la-mer.jpg'
import { Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import ContactForm from '../components/ContactForm'
import Footer from '../components/Footer'
import { motion } from 'framer-motion'

const HomeScreen = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: '-100vw',
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: '100vw',
      scale: 1.2,
    },
  }

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }
  return (
    <motion.div
      exit='out'
      animate='in'
      initial='initial'
      variants={pageVariants}
      transition={pageTransition}
    >
      <Row className='mr-0 ml-0' style={{ width: '100%' }}>
        <Col className='p-0'>
          <div
            className='background-image d-flex justify-content-center align-items-center'
            style={{ backgroundImage: 'url(' + BackgourndImage + ')' }}
          >
            <Image src={MainLogo} className='logo rounded'></Image>
          </div>
          <Image src={Stick} className='stick'></Image>
          <div className='disclaimer d-flex justify-content-center align-items-center'>
            <LinkContainer to='/projects'>
              <Button variant='custom'>Discover more</Button>
            </LinkContainer>
          </div>
        </Col>
      </Row>
      <div className='d-flex justify-content-center align-items-center'>
        <Image src={GreyStick} />
      </div>
      <Container className='py-5'>
        <Row className='text-center'>
          <Col>
            <h1>Company History</h1>
          </Col>
        </Row>
        <Row className='mt-3 d-flex justify-content-center align-items-center'>
          <Col lg={6} md={6} xs={12} sm={12}>
            <p>
              <span> Home Items Building Materials LLC </span>
              was established in Dubai in 2000. Initially, our main trade was
              Italian full body porcelain tiles, but as part of our expansion
              plans, we have subsequently incorporated in our portfolio other
              brands from Europe, South America and Asia as well as sanitary
              ware from Italy. The company operates in a showroom and offices of
              around 350 square meters located at Salah Addine Road, Deira,
              while our 10,000 square meter store housing approximately 100,000
              square meters materials at any given time, is located at DIP area.
              Our team of 35 staff is specialized in each of their field from
              marketing, design, supervision, administrative, accounting and
              logistics.
            </p>
          </Col>
          <Col lg={6} md={6} xs={12} sm={12}>
            <Image src={CompanyHistory} rounded width='100%'></Image>
          </Col>
        </Row>
      </Container>
      <div
        className='py-5'
        style={{ backgroundColor: '#e2e2e2', width: '100%' }}
      >
        <Container>
          <Row className='text-center'>
            <Col lg={12} md={12} xs={12} sm={12} className=''>
              <h1>Projects</h1>
            </Col>
          </Row>
          <Row className='text-center'>
            <Col>
              <p>
                Home Items leaves no stones unturned in achieving its
                commitments to clients and business associates in all its
                engagements. Staffed with well versed and highly trained
                employees and an efficient management team, whose combined
                efforts assures customers’ requirements are met and ultimately
                the same are satisfied above what is expected.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Image src={ProjectImage} width='100%' rounded />
            </Col>
          </Row>
          <Row className='text-center pt-3'>
            <Col>
              <h3
                style={{
                  fontFamily: 'Bold',
                  fontSize: '1.8rem',
                  color: '#fd2800',
                }}
              >
                Port de la mer Jumeriah - Dubai
              </h3>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center align-items-center text-center pt-3'>
            <Col>
              <LinkContainer to='/projects'>
                <Button variant='pmcustomer'>Discover More</Button>
              </LinkContainer>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className='py-5' id='contact'>
        <Row className='text-center'>
          <Col>
            <h1>Reach Out</h1>
          </Col>
        </Row>
        <Row className='text-center'>
          <Col>
            <a href='tel:+971506556977'>
              <p>Mobile: +971 506556977</p>
            </a>
          </Col>
        </Row>
        <Row className='text-center'>
          <Col>
            <a href='mailto:admin@homeitems.ae'>
              <p>Email: admin@homeitems.ae</p>
            </a>
          </Col>
        </Row>
        <ContactForm />
      </Container>
      <Footer />
    </motion.div>
  )
}

export default HomeScreen
