import React from 'react'
import { Card } from 'react-bootstrap'

const ProjectCard = ({ data }) => {
  return (
    <>
      <Card key={data.id} className='border-0 mt-3 project'>
        <Card.Img
          className='p-3 project-image'
          width='100%'
          height='400px'
          src={data.url}
          alt={data.title}
        />
        <Card.Body className='project-body'>
          <Card.Title>
            <h4>{data.title}</h4>
          </Card.Title>
          {data.client.length === 0 ? (
            ''
          ) : (
            <Card.Text>Client: {data.client}</Card.Text>
          )}
          {data.consultant.length === 0 ? (
            ''
          ) : (
            <Card.Text>Consultant: {data.consultant}</Card.Text>
          )}
          {data.contractor.length === 0 ? (
            ''
          ) : (
            <Card.Text>Contractor: {data.contractor}</Card.Text>
          )}
          {data.qty.length === 0 ? (
            ''
          ) : (
            <Card.Text>Qty. Supplied: {data.qty}</Card.Text>
          )}
          {data.value.length === 0 ? (
            ''
          ) : (
            <Card.Text>Project Value: {data.value} AED</Card.Text>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default ProjectCard
