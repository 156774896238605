import React, { useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'

const ContactForm = () => {
  const [validated, setValidated] = useState(false)

  const handleSubmit = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    setValidated(true)
  }
  return (
    <Form
      id='contact-form'
      name='ContactForm'
      method='post'
      action=''
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <Form.Group>
            <Form.Control
              type='text'
              placeholder='Full Name'
              name='name'
              required
            />
          </Form.Group>
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <Form.Group>
            <Form.Control
              type='tel'
              placeholder='Phone Number'
              name='number'
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <Form.Group>
            <Form.Control
              type='email'
              placeholder='Email'
              name='email'
              required
            />
          </Form.Group>
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <Form.Group>
            <Form.Control
              type='text'
              placeholder='Address'
              name='address'
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Control
              as='textarea'
              placeholder='Message'
              name='message'
              required
              rows={3}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className='text-center'>
        <Col>
          <Button variant='submitButton' type='submit'>
            Submit <i className='fas fa-arrow-circle-right'></i>
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default ContactForm
