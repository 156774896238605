const projects = [
  {
    id: 0,
    title: 'Al Fattan Towers',
    url: '/images/projects/Al-Fattan-Towers.jpg',
    client: '',
    consultant: '',
    contractor: '',
    qty: '',
    value: '',
  },
  {
    id: 1,
    title: 'Al Khaleej Center',
    url: '/images/projects/al-khaleej-centre-dubai.jpg',
    client: '',
    consultant: 'MEECON',
    contractor: '',
    qty: '40,000 Sq.M',
    value: '3,000,000',
  },
  {
    id: 2,
    title: 'Al Kharbash Tower 1',
    url: '/images/projects/Al-kharbash-tower-1.jpg',
    client: 'Al Kharbash',
    consultant: 'DAR',
    contractor: 'ANC',
    qty: '60,000 Sq.M',
    value: '3,000,000',
  },
  {
    id: 3,
    title: 'Al Kharbash Tower 2',
    url: '/images/projects/PROJECT-AL-KHARBASH-TOWER-II.jpg',
    client: '',
    consultant: '',
    contractor: '',
    qty: '',
    value: '',
  },
  {
    id: 4,
    title: 'Al Murooj',
    url: '/images/projects/PROJECT-AL-MUROOJ.jpg',
    client: 'Dubai Int. Real Estate',
    consultant: 'ARTEC',
    contractor: 'Al Ahadiah',
    qty: '45,000 Sq.M',
    value: '1,400,000',
  },
  {
    id: 5,
    title: 'Al Rostamani',
    url: '/images/projects/PROJECT-AL-ROSTAMANI-TOWER.jpg',
    client: '',
    consultant: 'Khatib & Alami',
    contractor: 'Al Rostamani',
    qty: '48,000 Sq.M',
    value: '1,900,000',
  },
  {
    id: 6,
    title: 'Sea Shell',
    url: '/images/projects/PROJECT-ALSALAMTOWER.jpg',
    client: 'Abdul Salam Rafiha',
    consultant: 'W/S Akins',
    contractor: 'DCC',
    qty: '65,000 Sq.M',
    value: '2,900,000',
  },
  {
    id: 7,
    title: 'Al Sari Twin Tower',
    url: '/images/projects/PROJECT-ALSARITWINTOWER.jpg',
    client: 'Al Sari',
    consultant: 'Arenco',
    contractor: 'Portobello',
    qty: '11,000 Sq.M',
    value: '880,000',
  },
  {
    id: 8,
    title: 'Al Seef Tower - U2',
    url: '/images/projects/PROJECT-ALSEEFTOWER-2.jpg',
    client: 'Deyaar',
    consultant: 'A&R',
    contractor: 'Ascon',
    qty: '50,000 Sq.M',
    value: '2,000,000',
  },
  {
    id: 9,
    title: 'Al Seef Tower',
    url: '/images/projects/PROJECT-ALSEEFTOWER.jpg',
    client: 'DIAR',
    consultant: 'DAR',
    contractor: 'Al Habtoor',
    qty: '60,000 Sq.M',
    value: '2,500,000',
  },
  {
    id: 10,
    title: 'Ary Tower',
    url: '/images/projects/PROJECT-ARYTOWER.jpg',
    client: 'Ary',
    consultant: 'Arenco',
    contractor: 'Al Hudaibah',
    qty: '60,000 Sq.M',
    value: '3,000,000',
  },
  {
    id: 11,
    title: 'Bagdadi - I',
    url: '/images/projects/PROJECT-BAGDADI-1.jpg',
    client: '',
    consultant: '',
    contractor: '',
    qty: '',
    value: '',
  },
  {
    id: 12,
    title: 'Sapphire Hotel Apartments',
    url: '/images/projects/PROJECT-BAGDADI-II.jpg',
    client: 'Bonyan',
    consultant: 'Ary & Bin Toak',
    contractor: '',
    qty: '35,556 Sq.M',
    value: '2,200,200',
  },
  {
    id: 13,
    title: 'BrightStar - II',
    url: '/images/projects/PROJECT-BRIGHTSTAR-2.jpg',
    client: '',
    consultant: 'W/S ATKINS',
    contractor: 'DCC',
    qty: '95,000 Sq.M',
    value: '4,300,000',
  },
  {
    id: 14,
    title: 'Burj Dubai The Residence',
    url: '/images/projects/PROJECT-BURJ_DUBAI_RESIDENCE.jpg',
    client: 'EMAAR',
    consultant: '',
    contractor: 'NASA Multiplex',
    qty: '80,000 Sq.M',
    value: '8,000,000',
  },
  {
    id: 15,
    title: 'Burj 2000',
    url: '/images/projects/PROJECT-BURJ2000.jpg',
    client: '',
    consultant: '',
    contractor: '',
    qty: '',
    value: '',
  },
  {
    id: 16,
    title: 'Capital Residence',
    url: '/images/projects/PROJECT-CAPITALRESIDENCE.jpg',
    client: 'Saeed Bin Belaila',
    consultant: '',
    contractor: '',
    qty: '14,000 Sq.M',
    value: '75,000',
  },
  {
    id: 17,
    title: 'Cascade Tower',
    url: '/images/projects/PROJECT-CASCADETOWER.jpg',
    client: '',
    consultant: 'Arif & Bin Toak',
    contractor: 'Al Sharaf General Trading',
    qty: '35,000 Sq.M',
    value: '1,500,000',
  },
  {
    id: 18,
    title: 'Discovry Gardens',
    url: '/images/projects/PROJECT-DISCOVERYGARDENS.jpg',
    client: 'Nakheel',
    consultant: 'ECG',
    contractor: '',
    qty: '1,000,000 Sq.M',
    value: '28,000,000',
  },
  {
    id: 19,
    title: 'Dubai Internet City',
    url: '/images/projects/PROJECT-DUBAIINTERNETCITY.jpg',
    client: 'DIC',
    consultant: 'Arenco',
    contractor: '',
    qty: '13,000 Sq.M',
    value: '850,000',
  },
  {
    id: 20,
    title: 'Dubai Oasis',
    url: '/images/projects/PROJECT-DUBAIOASIS.jpg',
    client: '',
    consultant: 'Adnan Safarni',
    contractor: 'Bin Ladin',
    qty: '60,000 Sq.M',
    value: '2,000,000',
  },
  {
    id: 21,
    title: 'Iranian Club',
    url: '/images/projects/PROJECT-IRANIANCLUB.JPG',
    client: 'Iranian Club',
    consultant: 'Farayand',
    contractor: 'Mirage',
    qty: '2,000 Sq.M',
    value: '900,000',
  },
  {
    id: 22,
    title: 'Jumeirah Lake',
    url: '/images/projects/PROJECT-JUMEIRALAKE.jpg',
    client: '',
    consultant: '',
    contractor: '',
    qty: '',
    value: '',
  },
  {
    id: 23,
    title: 'Jumeirah Residence',
    url: '/images/projects/PROJECT-JUMEIRARESIDENCE.jpeg',
    client: '',
    consultant: '',
    contractor: '',
    qty: '',
    value: '',
  },
  {
    id: 24,
    title: 'Le Meridien Abu Dhabi',
    url: '/images/projects/PROJECT-LEMERIDIENADH.jpeg',
    client: '',
    consultant: '',
    contractor: '',
    qty: '',
    value: '',
  },
  {
    id: 25,
    title: 'Marina Heights',
    url: '/images/projects/PROJECT-LULUHYPERMARKET.jpg',
    client: 'Abdul Salam Mohd Rafiah Group',
    consultant: 'RMJM',
    contractor: 'Dutco Balfour Beaty',
    qty: '90,000 Sq.M',
    value: '7,000,000',
  },
  {
    id: 26,
    title: 'Marina Residence',
    url: '/images/projects/PROJECT-MARINARESIDENCE.jpg',
    client: '',
    consultant: 'N.E.B',
    contractor: 'Portobello',
    qty: '13,000 Sq.M',
    value: '1,300,000',
  },
  {
    id: 27,
    title: 'Marina Sail',
    url: '/images/projects/PROJECT-MARINASAIL.jpg',
    client: 'Ibrahim Al Salehi',
    consultant: 'Khatib 7 Alami',
    contractor: 'Portobello & RPG',
    qty: '17,000 Sq.M',
    value: '1,800,000',
  },
  {
    id: 28,
    title: 'Meadows Villa',
    url: '/images/projects/PROJECT-MEADOWSVILLA.jpg',
    client: 'Emaar',
    consultant: 'Al Gurg',
    contractor: 'Al Hamad & ArabTec',
    qty: '80,000 Sq.M',
    value: '2,500,000',
  },
  {
    id: 29,
    title: 'Nasr Club Residential Complex',
    url: '/images/projects/PROJECT-NASRCLUBRESIDENTIALCOMPLEX.jpg',
    client: '',
    consultant: '',
    contractor: '',
    qty: '',
    value: '',
  },
  {
    id: 30,
    title: 'Iranian Club',
    url: '/images/projects/PROJECT-IRANIANCLUB.JPG',
    client: 'Iranian Club',
    consultant: 'Farayand',
    contractor: 'Mirage',
    qty: '2,000 Sq.M',
    value: '900,000',
  },
  {
    id: 31,
    title: 'Old Town Burj Dubai - Phase I&II',
    url: '/images/projects/PROJECT-OLDTOWNBURJDUBAI.jpeg',
    client: 'Emaar',
    consultant: 'D.J.Jones',
    contractor: 'Al Bakti & Muktha Phase I & Al Futtam Carillon Phase II',
    qty: '20,000 Sq.M',
    value: '5,800,000',
  },
  {
    id: 32,
    title: 'Rolla Residence',
    url: '/images/projects/PROJECT-ROLLARESIDENCE.jpg',
    client: '',
    consultant: '',
    contractor: '',
    qty: '',
    value: '',
  },
  {
    id: 33,
    title: 'Saba Real Estate',
    url: '/images/projects/PROJECT-SABAREALESTATE.jpg',
    client: '',
    consultant: '',
    contractor: '',
    qty: '',
    value: '',
  },
  {
    id: 34,
    title: 'Saba Towers',
    url: '/images/projects/PROJECT-SABATOWER.jpg',
    client: 'Saba Real Estate',
    consultant: 'RMJM',
    contractor: 'ACC',
    qty: '135,000 Sq.M',
    value: '5,850,000',
  },
  {
    id: 35,
    title: 'Sharjah College',
    url: '/images/projects/PROJECT-SHARJAHCOLLEGE.jpg',
    client: '',
    consultant: '',
    contractor: '',
    qty: '',
    value: '',
  },
  {
    id: 36,
    title: 'Sheikha Sheikha Complex',
    url: '/images/projects/PROJECT-SHEIKASHEIKACOMPLEX.jpg',
    client: '',
    consultant: '',
    contractor: '',
    qty: '',
    value: '',
  },
]

export default projects
