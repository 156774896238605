const services = [
  {
    id: 0,
    title: 'Design & Drafting Division',
    url: '/images/services/design.svg',
    desc:
      'Our technical assistance is always available for Architects, Contractors and owners. Bring your Villa, Building, Shopping Mall plan on Cd or a blue Print and let our Design department create your design up to you satisfaction.',
  },
  {
    id: 1,
    title: 'Cutting Factory',
    url: '/images/services/cutting.svg',
    desc:
      'Our company can serve the clients with the Skirting, Step treads, Borders and Water jet Designs. All these and much more are done by our cutting factory located in Al Qusais.',
  },
  {
    id: 2,
    title: 'Carpentry Factory',
    url: '/images/services/carpet.svg',
    desc:
      'Our technical assistance is always available for Architects, Contractors and owners. Bring your Villa, Building, Shopping Mall plan on Cd or a blue Print and let our Design department create your design up to you satisfaction.',
  },
]

export default services
